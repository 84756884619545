import { gql } from '@apollo/client';

export const GET_LOCAL_CACHE = gql`
  query GetLocalCache {
    getGloOptions @client
    getNotifications @client
  }
`;

export const GET_JOBS = gql`
  query GetJobs($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $isGhost: Boolean) {
    jobs(skip: $skip, take: $take, sort: $sort, filter: $filter, isGhost: $isGhost) {
      code
      success
      message
      payload {
        jobs {
          regId
          originalId
          active
          campaigh
          campaignNames {
            id
            name
          }
          campaignsId
          jobTitle
          feedId
          feedType
          category
          employer
          cpa
          cpc
          onsiteLocation
          state
          zip
          jobAdType
          dateAdded
          description
          exported
          netCostCpc
          netCostCpa
          dateUpdate
          boost
          clientname
          reqstatus
          lat
          lon
          feedName
          advertiserName
          url
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_JOBS_CHART = gql`
  query GetJobsChart($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter]) {
    jobsChart(skip: $skip, take: $take, sort: $sort, filter: $filter) {
      code
      success
      message
      payload {
        chart {
          partnerEmployer {
            key
            count
          }
          partnerCpc {
            key
            count
          }
          partnerId {
            key
            count
          }
          statePrefix {
            key
            count
          }
          advertiserId {
            key
            count
          }
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_FEEDS = gql`
  query GetFeeds($userEmail: String) {
    feeds(userEmail: $userEmail) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          active
          budget
          bidAdjustment
          liveJobs
          avgCPC
          totalSpend
          yesterday
          today
          dailyPace
          eom
          url
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_PUB_API_KEY = gql`
  query GetPubApiKey($userEmail: String) {
    pubApiKey(userEmail: $userEmail) {
      code
      success
      message
      payload {
        node {
          widgetKey
          pubFeeds {
            id
            name
            jobsApiKey
            minCpcNorm
            maxCpc
            revMtd
            maxPaidDist
            paidDuplicateClickTime
            maxPaidFraudScore
            type
            exportType
          }
        }
      }
    }
  }
`;

export const ACTION_FEED = gql`
  mutation ActionFeed($node: FeedInput, $type: String) {
    actionFeed(node: $node, type: $type) @client {
      id
    }
  }
`;

export const GET_EVENTS = gql`
  query GetEvents($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String) {
    events(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          events {
            id
            feedId
            advFeedName
            jobTitle
            employer
            partnerJobId
            clickId
            tqfId
            date
            type
            spendCpa
            spendCpc
            ip
            expired
            campaign
            paid
            unpaid
            excluded
            costCpc
            costCpa
            gloSrcId
            gloSubSrc
            rev
            distance
            gloSrc
            advertiser
            gloCamp
            gloGroup
            roi
            advertiserId
            gloGroupId
            gloCampId
            cpa
            ipqsFraudScore
            ipqsAbuseVelocity
            ipqsActiveTor
            ipqsActiveVpn
            ipqsBotStatus
            ipqsCrawler
            ipqsMobile
            ipqsProxy
            ipqsRecentAbuse
            ipqsTor
            ipqsVpn
            url
            cmpUrl
            referer
            p1
            p2
            p3
            unpaidReasonsText
            zip
          }
          charts {
            actionType {
              key
              count
            }
            gloSrc {
              key
              count
            }
            eventType {
              key
              count
            }
            partnerEmployer {
              key
              count
            }
            advertiser {
              key
              count
            }
          }
          count {
            spendCpa
            spendCpc
            costCpc
            costCpa
            type
            rev
          }
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_CATALOGS_EVENTS = gql`
  query GetCatalogEvents($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    catalogEvents(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      code
      success
      message
      payload {
        advertisersEvents {
          key
          name
          count
          isActive  
        }
        publishersEvents {
          key
          name
          count
        }
        employersEvents {
          key
          name
          count
        }
        actionEvents {
          key
          name
          count
        }
      }
    }
  }
`;

export const GET_CATALOGS_CAMPAIGNS = gql`
  query GetCatalogsCampaigns($isGhost: Boolean) {
    catalogsCampaigns(isGhost: $isGhost) {
      code
      success
      message
      payload {
        parentsCampaigns {
          key
          name
          count
        }
        feedsCampaigns {
          key
          name
          count
        }
        filtersCampaigns {
          key
          name
          count
        }
        groupsCampaigns {
          key
          name
          isActive  
          jatRating
          count
        }
        selectionsCampaigns {
          key
          name
        }
      }
    }
  }
`;

export const GET_REPORTS = gql`
  query GetReports($sort: [Sort], $filter: [Filter], $group: Group, $flagAddClicks: Boolean, $isCompare: Boolean, $compareCreateAt: String, $isRealtime: Boolean, $interval: String, $timeStart: String, $timeEnd: String) {
    reports(sort: $sort, filter: $filter, group: $group, flagAddClicks: $flagAddClicks, isCompare: $isCompare, compareCreateAt: $compareCreateAt, isRealtime: $isRealtime, interval: $interval, timeStart: $timeStart, timeEnd: $timeEnd) {
      code
      success
      message
      payload {
        nodes {
          report {
            grouped
            subgrouped
            groupedId
            subgroupedId
            events
            eventsPub
            eventsAdv
            cntCostCpc
            cntRevCpc
            applies
            ecpc
            cpc
            cpa
            costCpa
            costCpc
            avgVCpcCost
            avgVCpaCost
            avgVCpcRev
            avgVCpaRev
            costCpaPP
            costCpcPP
            avgCpc
            jobCount
            partnerCpc
            cr
            edrPubFeed
            edrAdvFeed
            edrRev
            rev
            agencyFee
            totalCostPTP
            roi
            roiPercent
            netRoi
            cost
            ecpa
            ecpac
            revEcpa
            tqfCr
            paid
            unpaid
            unpaidP
            blendCr
            deltaPercentCpc
            deltaPercentAvgCpc
            deltaPercentCr
            deltaPercentRev
            deltaPercentRoi
            compareCpc
            compareAvgCpc
            compareCr
            compareRev
            deltaRev
            deltaRoi
            deltaRoiPercent
            deltaCost
            deltaCr
            deltaEcpa
            compareDeltaRoi
            compareDeltaEcpa
            filteredPercent
            fcr
            ipqsFraudScoreLimit
            ipqsFraudScoreAvg
            editMatrix
          }
          chart {
            series {
              spend
              spendPubCpa
              spendPubCpc
              revenuePubTotal
              revenue
              revEcpa
              roi
              roiPercent
              avgCpc
              cpa
              jobs
              applies
              conversion
              revenueAdv
              paid
              unpaid
              unpaidP
              excluded
              events
              cntRevCpc
              cntCostCpc
              partnerCpc
              cr
              blendCr
              tqfCr
              fcr
              ecpa
              ipqsFraudScoreLimit
              ipqsFraudScoreAvg
              duplicateClicks
              targetCrLine
            }
            categories
          }
          summary {
            cpc
            costCpc
            costCpcPP
            revCpc
            partnerCpc
            revCpa
            partnerCpa
            cpa
            costCpa
            costCpaPP
            agencyFee
            roi
            roiAf
            roiAdv
            roiAdvAf
            cntCostCpc
            cntCostCpa
            duplicateClicks
            eventsPub
            cntRevCpc
            revCpaCount
            applyCount
            applies
            events
            eventsAdv
            paid
            unpaid
            unpaidP
            dup
            noDup
            avgCpc
            avgCpcCost
            roiPub
            roiPercent
            roiPercentAf
            ipqsFraudScoreAvg
            ipqsFraudScoreLimit
            cost
            totalCostPTP
            rev
            revEcpa
            revEdrAdv
            revEdrAdvAf
            cr
            ecpa
            ecpaPaid
            ecpc
            ecpac
            ecpcRaw
            ecpaRaw
            fcr
            fcrRaw
            filtered
            filteredPercent
            jobCount
            costJobViewDlTqf
            tqfCr
            avgVCpcCost
            avgVCpaCost
            avgVCpcRev
            avgVCpaRev
            edrPubFeed
            edrAdvFeed
            deltaPercentCpc
            deltaPercentAvgCpc
            deltaPercentCr
            deltaPercentRev
            deltaPercentRoi
            compareCpc
            compareAvgCpc
            compareCr
            compareRev
            deltaRoi
            compareDeltaRoi
          }
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_MATRIX = gql`
  query GetMatrix($filter: [Filter], $group: Group, $isCompare: Boolean, $compareCreateAt: String, $isRealtime: Boolean, $isGhost: Boolean, $matrixViewId: Int,) {
    matrix(filter: $filter, group: $group, isCompare: $isCompare, compareCreateAt: $compareCreateAt, isRealtime: $isRealtime, isGhost: $isGhost, matrixViewId: $matrixViewId,) {
      code
      success
      message
      payload {
        nodes {
          matrix {
            uid
            grouped
            subgrouped
            groupedId
            subgroupedId
            rev
            roi
            roiPercent
            bidAdjustment
            sign
            format
            grossRoi
            cost
            rkj
            pkj
            cr
            tqfAdjId
            spTqfId
            jobsCount
            tqfLastIntentCr
            filteredPercent
            ecpa
            minCpc
            maxCpc
            minCpcNorm
            countGroup
            countSubgroup
            isActive
            deltaRev
            deltaRoi
            deltaRoiPercent
            deltaCost
            deltaCr
            deltaEcpa
            costTotal
            tqf
            tqfCr
#            uid
#            grouped
#            subgrouped
#            groupedId
#            subgroupedId
#            cr
#            rev
#            roi
#            roiPercent
#            costTotal
#            cost
#            rkj
#            pkj
#            ecpa
#            ecpaCost
#            ecpaRev
#            ecpcCost
#            ecpcRev
#            deltaRev
#            deltaRoi
#            deltaRoiPercent
#            deltaCost
#            deltaCr
#            deltaEcpa
#            campaign
#            jobsCount
#            numJobs
#            tqf
#            filteredPercent
#            tqfCr
#            tqfLastIntentCr
#            tqfLastIntentCrDate
#            totalAdj
#            lastChangesDate
#            lastChanges
#            grossRoi
#            costCpaAdj
#            minCpc
#            maxCpc
#            minCpa
#            maxCpa
#            minCpcNorm
#            bidAdjustment
#            sign
#            format
#            avgCpc
#            avgCpcCost
#            avgCpcRev
#            costCpa
#            cpcCpaCnvrVal
#            notes
#            avgCpa
#            avgCpaRev
#            dayBudget
#            isActive
#            countSubgroup
#            countGroup
#            payType
#            spTqfId
#            jobCountLimit
#            blendCr
#            clickAvg
#            tqfDeltaCr
#            tqfBoost
          }
          campaignGroupsInfo {
            id
            name
            jobCount
            jatRating
            volumeSting
            volume
            qualityString
            quality
            rating
            cr
            lastIntentCr
            lastIntentCrDate
          }
          campaignsInfo {
            id
            name
            jobCount
            jatRating
            rating
            level
            bid
            avgCpc
            margin
            trackCr
            cr
            ecpa
            costCpcAvg
          }
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_MATRIX_STATS = gql`
  query GetMatrixStats($filter: [Filter], $id: Int) {
    getMatrixStats(filter: $filter, id: $id) {
      code
      success
      message
      payload {
        node {
          filtered
          cpac
          revMtd
          costMtd
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_MATRIX_ID = gql`
  query GetMatrixByID($id: Int, $filter: [Filter]) {
    getMatrix(id: $id, filter: $filter) {
      code
      success
      message
      payload {
        node {
          uid
          isActive
          campaignName
          campaignGroupName
          isBlockCampaign
          isActiveUntilBudget
          bidAdjustment
          sign
          format
          maxCpc
          minCpc
          minCpcFilter
          maxCpa
          minCpa
          minCpaFilter
          targetCpa
          targetCpaCost
          dayBudget
          monthBudget
          totalBudget
          optimize
          expiredWhere
          rdr2Boomerang
          botProtection
          fraudProtection
          fraudScoreFilter
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          jobCountLimit
          totalIncludedJobs
          finalMargin
          finalMinCpc
          finalMinCpa
          finalMinCpcNorm
          finalMaxCpcNorm
          finalMinCpaNorm
          finalMaxCpaNorm
          pubFeedMargin
          pubFeedMinCpc
          pubFeedMinCpcNorm
          pubFeedMaxCpcNorm
          pubFeedMinCpa
          pubFeedMinCpaNorm
          pubFeedMaxCpaNorm
          numJobs
          grossRoi
          lastChanges
          lastChangesMore
          isActiveTqf
          tqfId
          tqf
          tqfType
          tqfLastIntentCr
          tqfLastIntentCrDate
          costCpaAdj
          cpcCpaCnvrVal
          payType
          recoupAllTraffic
          notes
          feedNetCpc
          feedMinCpc
          feedMaxNetCpcNorm
          feedMaxNetCpaNorm
          feedType
          campExclusive
          campFilters
          campGroupNetCpc
          cgMinCpc
          cgMinCpcNorm
          cgMaxCpcNorm
          feedId
          feedName
          advConvTracking
          pubConvTracking
          advBudget
          pubBudget
          jobMatchEnabled
          jobMatchLimitType
          jobMatchLimitValue
          targetCr
          exportFeedType
          tApplyEnabled
          tApplyPercentage
          tApplyVariability
          tApplyMinDelaySec
          tApplyMaxDelaySec
          tqfDeltaCr
          tqfBoost
          avgCpc
          avgCpa
          ecpa
          ecpaPaid
          ecpac
          ecpc
          jobIdCount
          applyCount
          cntApplies
          cntCostCpc
          costCpcCount
          cntCostCpa
          cntRevCpc
          agencyFee
          costCpcAvg
          exitClickCount
          cnt
          cntPaid
          partnerCpa
          partnerCpc
          rev
          revCpa
          revCpc
          revEdrAdv
          revEdrAdvAf
          revCpaCount
          revCpcCount
          cost
          costCpc
          costCpa
          roi
          roiPercent
          roiPercentAf
          roiAf
          roiPub
          roiAdv
          roiAdvAf
          filtered
          filteredPercent
          cr
          tqfCr
          fcrRaw
          fcr
          ipqsFraudScoreAvg
          ipqsFraudScoreLimit
          vCostCpcAvg
          vCostCpaAvg
          vRevCpcAvg
          vRevCpaAvg
          countDup
          countNoDup
          ecpcRaw
          ecpaRaw
          costJobViewDlTqf
          cpac
          blendCr
          clickAvg
          deltaCr
          unpaid
          dayEstimate
          totalNetCpc
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_MATRIX_ITEM_ID = gql`
  query GetMatrixItemID($campId: Int, $campGroupId: Int) {
    getMatrixItemId(campId: $campId, campGroupId: $campGroupId) {
      code
      success
      message
      payload {
        node {
          id
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_MATRIX_HISTORY = gql`
  query GetMatrixHistory {
    getMatrixHistory {
      code
      success
      message
      payload {
        nodes {
          id
          userId
          name
          filters
          createdAt
        }
      }
    }
  }
`;

export const GET_CATALOGS = gql`
  query GetCatalogs($isGhost: Boolean, $userEmail: String) {
    catalogs(isGhost: $isGhost, userEmail: $userEmail)  {
      code
      success
      message
      payload {
        userInfo {
          siteAdminId
        }  
        camp {
          key
          name
          isActive
          rating
          volume
          count
        }
        group {
          key
          name
          isActive
          count
          parent
        }
        traders {
          key
          name
        }
        sites {
          key
          name
        }
        tqfInfo {
          key
          name
        }
        feeds {
          key
          name
          isActive
          parent
        }
        advertisers {
          key
          name
          isActive
        }
        advertisersId {
          key
          name
        }
        publishers {
          key
          name
          isActive
        }
        sales {
            key
            name
        }
        paidClicks {
            key
            name
        }
        cpaJobsPayout {
            key
            name
        }
        exportTypePub {
            key
            name
        }
        ipqsFraudScoreLimit {
          key
          name
        }
        payType {
          key
          name
        }
        feedTypesJobs {
          key
          name
        }
        feedTypes {
          key
          name
        }
        feedQuality {
          key
          name
        }
        feedStatus {
          key
          name
        }
        importTypes {
          key
          name
        }
        feedExpired {
          key
          name
        }
        action {
          key
          name
        }
        exportType {
          key
          name
        }
        expired {
          key
          name
        }
        employers {
          key
          name
        }
        partnerType {
          key
          name
        }
        excluded {
          key
          name
        }
        paid {
          key
          name
        }
        selectBooleanList {
          key
          name
        }
        filterByTracking {
          key
          name
        }
        typeAdv {
          key
          name
        }
        schemaAdv {
          key
          name
        }
        rating {
          key
          name
        }
        contentType {
          key
          name
        }
        jobAdType {
          key
          name
        }
        tqfTypes {
          key
          name
        }
        tqfMenuTypes {
          key
          name
        }
        unpaidReasons {
          key
          name
        }
        mediumSrc {
          key
          name
        }
        jatRating {
          key
          name
          jatRating
        }
        jatFilter {
          key
          name
        }
        groupByMatrix {
          key
          name
        }
        booleanSelect {
          key
          name
        }
        specifTrendsMatrix {
          key
          name
        }
        specifCompareTrendsMatrix {
          key
          name
        }
        billOff {
          key
          name
        }
        logsType {
          key
          name
        }
        grouping {
          key
          name
          line
        }
        subgrouping {
          key
          name
        }
      }
    }
  }
`;

export const GET_GLO_OPTIONS = gql`
  query GetGloOptions {
    getGloOptions @client
  }
`;

// TODO: $options - why? там фильтры группировка и сортировка
export const EDIT_GLO_OPTIONS = gql`
  mutation EditGloOptions($type: String!, $options: [Sort]!) {
    editGloOptions(type: $type, options: $options) @client {
      type
    }
  }
`;

export const GET_GROUPS = gql`
  query GetGroups {
    groups {
      code
      success
      message
      payload {
        nodes {
          id
          name
          filters
          minRev
        }
      }
    }
  }
`;

export const UPLOAD_GROUP = gql`
  mutation UploadGroup($name: String, $type: String, $minRev: Int, $filters: [String]) {
    updateGroups(name: $name, type: $type, minRev: $minRev, filters: $filters) {
      code
      success
      message
      payload {
        node {
          id
          name
          minRev
          filters
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const DELETE_GROUP = gql`
    mutation DeleteGroups($id: Int) {
        deleteGroups(id: $id) {
            code
            success
            message
            payload {
                node {
                    id
                    name
                }
                meta {
                    totalCount
                }
            }
        }
    }
`;

export const GET_IMPORT_JOBS_COUNT = gql`
  query GetImportJobsCount($filter: [Filter], $group: String, $interval: String) {
    importJobsCount(filter: $filter, group: $group, interval: $interval) {
      payload {
        chart {
          categories
          series
        }
      }
    }
  }
`;

export const GET_EXPORT_JOBS_COUNT = gql`
  query GetExportJobsCount($filter: [Filter], $group: String, $interval: String) {
    exportJobsCount(filter: $filter, group: $group, interval: $interval) {
      payload {
        chart {
          categories
          series
        }
      }
    }
  }
`;

export const GET_ADVERTISER = gql`
  query GetAdvertisers($filter: [Filter], $filterType: String, $group: String) {
    advertisers(filter: $filter, filterType: $filterType, group: $group) {
      payload {
        nodes {
          id
          date
          updated
          advertiser
          feedId
          publisher
          publisherId
          portal
          revenue
          feedType
          gloRev
          deltaRev
          deltaRevP
          jobsCount
          appliesPaid
          appliesUnpaid
          appliesTotal
          clicksPaid
          clicksUnpaid
          clicksTotal
          gloClicks
          cpaPaid
          cpaTotal
          cpcPaid
          cpcTotal
          crPaid
          crTotal
        }
        chart {
          categories
          series
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_PUBLISHER = gql`
  query GetPublishers($filter: [Filter], $filterType: String, $group: String) {
    publishers(filter: $filter, filterType: $filterType, group: $group) {
      payload {
        nodes {
          id
          date
          updated
          publisher
          publisherId
          gloPublisher
          pubFeedId
          gloPubFeed
          campaign
          campaignId
          cost
          gloCost
          deltaCost
          deltaCostP
          jobsCount
          appliesPaid
          appliesUnpaid
          appliesTotal
          clicksPaid
          clicksUnpaid
          clicksTotal
          gloClicks
          cpaPaid
          cpaTotal
          cpcPaid
          cpcTotal
          crPaid
          crTotal
          gloPubFeedUrl
        }
        chart {
          categories
          series
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_OVERVIEW_CHARTS = gql`
  query GetReportsChart($filter: [Filter], $group: String, $isCompare: Boolean, $compareCreateAt: String, $field: String, $isGhost: Boolean) {
    reportsChart(filter: $filter, group: $group, isCompare: $isCompare, compareCreateAt: $compareCreateAt, field: $field, isGhost: $isGhost) {
      payload {
        nodes {
          chart {
            y
            name
          }
          compareChart {
            y
            name
          }
          deltaChart {
            y
            name
          }
        }
      }
    }
  }
`;

export const GET_TOP_GAINERS_CHART = gql`
  query GetTopGainersChart($filter: [Filter], $group: String, $field: String, $isGhost: Boolean) {
    topGainersChart(filter: $filter, group: $group, field: $field, isGhost: $isGhost) {
      payload {
        nodes {
          chart {
            key
            name
            title
            label
            data {
              name
              value
              valuePercent
            }
            type
            percent
            currency
            y
          }
        }
      }
    }
  }
`;

export const GET_CAMPAIGNS = gql`
  query GetCampaigns($filter: [Filter], $filterType: String, $isDeleted: Boolean) {
    campaigns(filter: $filter, filterType: $filterType, isDeleted: $isDeleted) {
      payload {
        nodes {
          id
          name
          checkbox
          revPayType
          rating
          level
          parentId
          parentName
          targetCpa
          targetCpaCost
          feedId
          feedName
          tqfId
          tqf
          minCpc
          filters
          groups
          jobsCount
          jatRating
          cdate
          monthBudget
          dayBudget
          comment
          exclusive
          fixedCpa
          netCpc
          bid
          rev
          roi
          cr
          advertiserId
          advertiserName
          recoupAllTraffic
          tapplyEnabled
          tapplyPercentage
          tapplyVariability
          description
          pubFeeds {
            id
            pubFeedId
            campaignId
            title
            numJobs
          }
          pubFeedsEnabled
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_BY_ID = gql`
  query getCampaignById($id: Int, $filter: [Filter]) {
    getCampaign(id: $id, filter: $filter) {
      code
      success
      message
      payload {
        node {
          id
          name
          rating
          level
          parentId
          parentName
          targetCpa
          targetCpaCost
          feedId
          feedName
          minCpc
          filters
          groups
          jobsCount
          jatRating
          cdate
          monthBudget
          dayBudget
          comment
          exclusive
          fixedCpa
          netCpc
          bid
          advertiserId
          advertiserName
          recoupAllTraffic
          tapplyEnabled
          tapplyPercentage
          tapplyVariability
          isScaling
          reduceCostEnabled
          reduceCost
          description
          activePublishers
          targetRoi
          trackCr
          tqfDeltaCr
          tqfBoost
          avgCpc
          avgCpa
          ecpa
          ecpaPaid
          ecpac
          ecpc
          jobIdCount
          applyCount
          cntApplies
          cntCostCpc
          costCpcCount
          cntCostCpa
          cntRevCpc
          agencyFee
          costCpcAvg
          exitClickCount
          cnt
          cntPaid
          partnerCpa
          partnerCpc
          rev
          revCpa
          revCpc
          revEdrAdv
          revEdrAdvAf
          revCpaCount
          revCpcCount
          cost
          costCpc
          costCpa
          roi
          roiPercent
          roiPercentAf
          roiAf
          roiPub
          roiAdv
          roiAdvAf
          filtered
          filteredPercent
          cr
          tqfCr
          fcrRaw
          fcr
          ipqsFraudScoreAvg
          ipqsFraudScoreLimit
          vCostCpcAvg
          vCostCpaAvg
          vRevCpcAvg
          vRevCpaAvg
          countDup
          countNoDup
          ecpcRaw
          ecpaRaw
          costJobViewDlTqf
          cpac
          blendCr
          clickAvg
          deltaCr
          unpaid
          dayEstimate
        }
      }
    }
  }
`;

export const GET_PUB_FEEDS_BY_CAMP_ID = gql`
  query GetPubFeedsByCampId($skip: Int, $take: Int, $sort: [Sort], $id: Int, $notLinked: Boolean, $name: String) {
    getPubFeedsByCampId(skip: $skip, take: $take, sort: $sort, id: $id, notLinked: $notLinked, name: $name) {
      payload {
        nodes {
          id
          isActive
          name
          rev
          roi
          costCpc
          costCpa
          cr
          tqfCr
          campaignId
          matrixId
          netCpc
          tqf
          tqfId
          sign
          format
          quality
          profitability
          autoQuality
          isReSyndicated
          jatRating
          clickAvg
          editMatrix
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_TQM = gql`
  query GetTqm($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String) {
    tqm(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_CATALOG_REPORTS = gql`
  query GetCatalogReports($filter: [Filter], $isGhost: Boolean) {
    catalogReports(filter: $filter, isGhost: $isGhost) {
      code
      success
      message
      payload {
        camp {
          key
          name
          isActive
        }
        group {
          key
          name
          isActive
        }
        feeds {
          key
          name
          isActive
        }
        pubs {
            key
            name
            isActive
        }  
      }
    }
  }
`;

export const DELETE_CAMPAIGNS = gql`
  mutation DeleteCampaign($ids: String) {
    deleteCampaigns(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const ARCHIVE_CAMPAIGNS = gql`
  mutation ArchiveCampaign($ids: String) {
    archiveCampaigns(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const CLONE_CAMPAIGNS = gql`
  mutation CloneCampaign($ids: String) {
    cloneCampaigns(ids: $ids) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          revPayType
          rating
          level
          parentId
          parentName
          targetCpa
          targetCpaCost
          feedId
          feedName
          tqf
          minCpc
          filters
          groups
          jobsCount
          jatRating
          cdate
          monthBudget
          dayBudget
          comment
          exclusive
          fixedCpa
          netCpc
          bid
          rev
          advertiserId
          advertiserName
          recoupAllTraffic
          tapplyEnabled
          tapplyPercentage
          tapplyVariability
          pubFeeds {
            id
            pubFeedId
            campaignId
            title
            numJobs
          }
          pubFeedsEnabled
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const ADD_CAMPAIGNS = gql`
  mutation AddCampaign($node: CampaignInput) {
    addCampaigns(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          rating
          level
          parentName
          parentId
          feedName
          feedId
          targetCpa
          targetCpaCost
          minCpc
          monthBudget
          dayBudget
          comment
          fixedCpa
          rev
          jatRating
          netCpc
          bid
          name
          filters
          groups
          cdate
          jobsCount
          recoupAllTraffic
          userEmail
          tapplyEnabled
          tapplyPercentage
          tapplyVariability
          isScaling
        }
      }
    }
  }
`;

export const UPDATE_CAMPAIGNS = gql`
  mutation UpdateCampaign($node: CampaignInput, $id: Int) {
    updateCampaigns(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          rating
          level
          parentName
          parentId
          feedName
          feedId
          targetCpa
          targetCpaCost
          targetRoi
          minCpc
          monthBudget
          dayBudget
          comment
          fixedCpa
          rev
          jatRating
          netCpc
          bid
          name
#          filters
          groups
          cdate
          jobsCount
          recoupAllTraffic
          userEmail
          tapplyEnabled
          tapplyPercentage
          tapplyVariability
          isScaling
          reduceCost
          description
          test
        }
      }
    }
  }
`;

export const UPDATE_CUT_CAMPAIGN = gql`
  mutation UpdateCutCampaign($node: CutCampaignActionInput, $id: Int, $matrixId: Int, $groupedId: Int, $subgroupedId: Int) {
    updateCutCampaign(node: $node, id: $id, matrixId: $matrixId, groupedId: $groupedId, subgroupedId: $subgroupedId) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          netCpc
          sign
          format
          tqf
          tqfId
          matrixId
          campaignId
          quality
          profitability
          autoQuality
        }
      }
    }
  }
`;

export const FIND_PUB_FEEDS = gql`
  query FindPubFeeds($id: Int) {
    findPubFeeds(id: $id) {
      payload {
        nodes {
          key
          name
        }
      }
    }
  }
`;

export const UPDATE_CUT_PUB_FEED = gql`
  mutation UpdateCutPubFeed($node: CutPubFeedActionInput, $id: Int, $matrixId: Int, $groupedId: Int, $subgroupedId: Int) {
    updateCutPubFeed(node: $node, id: $id, matrixId: $matrixId, groupedId: $groupedId, subgroupedId: $subgroupedId) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          netCpc
          sign
          format
          tqf
          tqfId
          matrixId
          campaignId
          quality
          profitability
          autoQuality
        }
      }
    }
  }
`;

export const GET_LAST_IMPORT_REPORT = gql`
  query GetLastImportReport {
    lastImportReport {
      code
      success
      message
      payload {
        nodes {
          importData {
            createdAt
            liveJobCount
            liveJobDelta
            jobPercentChange
            feeds {
              id
              name
              totalJobCount
              totalJobDelta
              liveJobCount
              liveJobDelta
              jobPercentChange
              isPaused
            }
          }
          frequency
        }
      }
    }
  }
`;

export const GET_IMPORT_DUPLICATES = gql`
  query getImportDuplicates($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    getImportDuplicates(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          partnerEmployer
          partnerId
          avgCpc
          cnt
          isApproved
          winMaxCpc
          winAvgCpc
          winFeedId
          type
          advertiserId
          advertiser
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_IMPORT_BLOCKED = gql`
  query getImportBlocked($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    getImportBlocked(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          checkbox
          company
          partnerId
          duplicate
          hCpc
          priority
          jobs
          feedName
          feedType
          costType
          rating
          isApproved
          createdAt
          updatedAt
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const DELETE_IMPORT_BLOCKED = gql`
  mutation DeleteImportBlocked($ids: String) {
    deleteImportBlocked(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const APPROVE_IMPORT_BLOCKED = gql`
  mutation ApproveImportBlocked($ids: String) {
    approveImportBlocked(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const DECLINE_IMPORT_BLOCKED = gql`
  mutation DeclineImportBlocked($ids: String) {
    declineImportBlocked(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const GET_IMPORT_HISTORY = gql`
  query getImportHistory($id: Int) {
    getImportHistory(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          history {
            id
            log
            userId
          }
        }
      }
    }
  }
`;

export const GET_IMPORT = gql`
  query GetImport($filter: [Filter], $filterType: String) {
    import(filter: $filter, filterType: $filterType) {
      payload {
        nodes {
          id
          tqfId
          partnerId
          traderId
          trader
          executiveId
          executive
          isActive
          isDisabled
          isExcludeFromApi
          createAif
          name
          status
          type
          advertiserId
          advertiser
          url
          margin
          marginCpa
          acfMinCpc
          acfMinCpa
          minCpc
          targetCpa
          targetCpaCost
          targetCr
          trackCr
          jobsCount
          jobsCountTotal
          lastImportedJobs
          budget
          dailyBudget
          stopBudgetExceed
          uniqueJobExport
          lastImported
          decreasing
          jatRating
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          yrevenue
          mtdRevenue
          ecpa
          yagencyFee
          eom
          yroi
          yRoiVal
          cr
          rkj
          pkj
          jobMatchLimit
          avgCpc
          avgCpcDelta
          rating
          contentType
          content
          edr
          volume
          blendCr
          expiredTimer
          feedExpired
          isAcceptJobMatch
          isShowJobs
          isApi
          isRecoup
          goal
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          spTqfId
          liveCnt
          jobsLiveTrend
          importCnt
          netRoip
          netRoi
          cpcCpaCnvrVal
          feedAgencyFee
          pace
          recoupAllTraffic
          maxNetCpcNorm
          maxNetCpcNormEnabled
          maxNetCpaNorm
          maxNetCpaNormEnabled
          dayTarget
          showInTqf
          showJobs
          enableImportToLJ
          trafficType
          linkUrl
          linkKeyword
          linkZip
          advEmail
          createAif
          aif
          aifExist
          importJobLimit
          addSubId
          siteAdminId
          siteAdmin
          priority
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_IMPORT_BY_ID = gql`
  query GetImportById($id: Int) {
    getImport(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          traderId
          trader
          executive
          executiveId
          advertiser
          advertiserId
          isActive
          isDisabled
          createAif
          name
          status
          url
          margin
          marginCpa
          acfMinCpc
          acfMinCpa
          minCpc
          targetCpa
          targetCpaCost
          targetCr
          trackCr
          content
          jatRating
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          budget
          dailyBudget
          stopBudgetExceed
          expiredTimer
          feedExpired
          isAcceptJobMatch
          isShowJobs
          isApi
          isRecoup
          rating
          goal
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          jobMatchLimit
          recoupAllTraffic
          maxNetCpcNorm
          maxNetCpcNormEnabled
          maxNetCpaNorm
          maxNetCpaNormEnabled
          cpcCpaCnvrVal
          feedAgencyFee
          pace  
          trafficType
          linkUrl
          linkKeyword
          linkZip
          importJobLimit
          addSubId
          priority
        }
      }
    }
  }
`;

export const CLONE_IMPORT = gql`
  mutation CloneImport($id: Int) {
    cloneImport(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          tqfId
          partnerId
          traderId
          trader
          executiveId
          executive
          isActive
          isDisabled
          isExcludeFromApi
          createAif
          name
          status
          type
          advertiserId
          advertiser
          url
          margin
          marginCpa
          acfMinCpc
          acfMinCpa
          minCpc
          targetCpa
          targetCpaCost
          targetCr
          trackCr
          jobsCount
          jobsCountTotal
          lastImportedJobs
          budget
          dailyBudget
          stopBudgetExceed
          uniqueJobExport
          lastImported
          decreasing
          jatRating
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          yrevenue
          mtdRevenue
          ecpa
          yagencyFee
          eom
          yroi
          yRoiVal
          cr
          rkj
          pkj
          jobMatchLimit
          avgCpc
          avgCpcDelta
          rating
          contentType
          content
          edr
          volume
          blendCr
          expiredTimer
          feedExpired
          isAcceptJobMatch
          isShowJobs
          isApi
          isRecoup
          goal
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          spTqfId
          liveCnt
          jobsLiveTrend
          importCnt
          netRoip
          netRoi
          cpcCpaCnvrVal
          feedAgencyFee
          pace
          recoupAllTraffic
          maxNetCpcNorm
          maxNetCpcNormEnabled
          maxNetCpaNorm
          maxNetCpaNormEnabled
          dayTarget
          showInTqf
          showJobs
          enableImportToLJ
          trafficType
          linkUrl
          linkKeyword
          linkZip
          advEmail
          createAif
          aif
          aifExist
          importJobLimit
          addSubId
          siteAdminId
          siteAdmin
          priority
        }
      }
    }
  }
`;

export const ADD_IMPORT = gql`
  mutation AddImport($node: ImportAddInput) {
    addImport(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          traderId
          trader
          executiveId
          executive
          isActive
          isDisabled
          name
          status
          advertiser
          advertiserId
          url
          margin
          marginCpa
          acfMinCpc
          acfMinCpa
#          minCpc
          targetCr
          trackCr
          jobsCount
          lastImportedJobs
          budget
          uniqueJobExport
          lastImported
          jatRating
          dailyBudget
          stopBudgetExceed
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          yrevenue
          mtdRevenue
          eom
          yroi
          cr
          avgCpc
          avgCpcDelta
          rating
          contentType
          content
          edr
          volume
          expiredTimer
          feedExpired
          isAcceptJobMatch
          isShowJobs
          isApi
          isRecoup
          goal
          recoupAllTraffic
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          jobMatchLimit
          pace
          maxNetCpcNorm
          maxNetCpcNormEnabled
          maxNetCpaNorm
          maxNetCpaNormEnabled
          trafficType
          linkUrl
          linkKeyword
          linkZip
          importJobLimit
        }
      }
    }
  }
`;

export const UPDATE_IMPORT = gql`
  mutation UpdateImport($node: ImportUpdateInput, $id: Int) {
    updateImport(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          traderId
          trader
          executiveId
          executive
          isActive
          isDisabled
          createAif
          name
          status
          advertiserId
          url
          margin
          marginCpa
          acfMinCpc
          acfMinCpa
#          minCpc
          targetCpa
          targetCpaCost
          targetCr
          trackCr
          jobsCount
          uniqueJobExport
          lastImported
          yrevenue
          yroi
          cr
          avgCpc
          avgCpcDelta
          contentType
          content
          jatRating
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          budget
          dailyBudget
          stopBudgetExceed
          edr
          expiredTimer
          feedExpired
          isAcceptJobMatch
          isShowJobs
          isApi
          isRecoup
          rating
          goal
          spProtection
          spClicks
          spMoney
          spPeriod
          spType
          jobMatchLimit
          pace
          recoupAllTraffic
          maxNetCpcNorm
          maxNetCpcNormEnabled
          maxNetCpaNorm   
          maxNetCpaNormEnabled
          cpcCpaCnvrVal
          feedAgencyFee
          trafficType
          linkUrl
          linkKeyword
          linkZip
          importJobLimit
          addSubId
          priority
        }
      }
    }
  }
`;

export const ACTIVATE_IMPORT_FEED = gql`
  mutation ActivateImportFeedReport($id: Int) {
    activateImportFeedReport(id: $id) {
      code
      success
      message
      payload {
          id
      }
    }
  }
`;

export const ENABLE_IMPORT_TO_JOB = gql`
  mutation EnableImportToJob($id: Int) {
    enableImportToJob(id: $id) {
      code
      success
      message
      payload {
        id
        campaignId
      }
    }
  }
`;

export const CLOSE_JOB_BY_IMPORT = gql`
  mutation CloseJobByImport($id: Int) {
    closeJobByImport(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const DELETE_IMPORT = gql`
  mutation DeleteImport($id: Int) {
    deleteImport(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const ACTIVATE_ALL_IMPORT = gql`
    mutation ActivateAllImport {
        activateAllImport {
            code
            success
            message
        }
    }
`;

export const GET_LAST_EXPORT_REPORT = gql`
  query GetLastExportReport {
    lastExportReport {
      code
      success
      message
      payload {
        nodes {
          exportData {
            createdAt
            liveJobCount
            liveJobDelta
            feeds {
              id
              feedName
              liveJobCount
              liveJobDelta
              lastPickup
            }
          }
          frequency
        }
      }
    }
  }
`;

export const GET_EXPORT = gql`
  query GetExport($filter: [Filter], $filterType: String, $isGhost: Boolean) {
    export(filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          name
          tqfId
          traderId
          trader
          siteAdminId
          siteAdmin
          executiveId
          executive
          status
          payType
          live
          isDisabled
          isReSyndicated
          jobsCount
          jobsCountLimit
          queueJobsToggle
          minCpcNorm
          maxCpc
          minCpc
          maxCpa
          minCpa
          minCpaNorm
          cpcCpaCnvrVal
          expiredWhere
          netCpc
          targetCr
          lastDownload
          jatRating
          jatExclude
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          feedDate
          decreasing
          redirectTo
          feedFile
          publisher
          publisherId
          quality
          volume
          ecpa
          edr
          cr
          rkj
          pkj
          jobsCountTrend
          budget
          dailyBudget
          stopBudgetExceed
          revMtd
          mtdRevenue
          goal
          costMtd
          roiMtd
          roiMtdP
          clickAvg
          blendCr
          feedType
          costCpaMtd
          costAvgCpa
          recoupAllTraffic
          eom
          pace
          mediumSrc  
          costCpaAdj
          jobsCountHistory {
            key
            name
          }
          tqfList {
            id
            name
            campaignGroupId
          }
          showInReports
          showInTqf
          editSCF
          campsEnabled
          pubEmail
          jobsApiKey
          skipTqf
          pubQuality
          pubProfitability
          pubAutoQuality
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_EXPORT_BY_ID = gql`
  query getExportById($id: Int) {
    getExport(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          name
          traderId
          executiveId
          siteAdminId
          status
          payType
          live
          isDisabled
          isReSyndicated
          jobsCount
          jobsCountLimit
          queueJobsToggle
          minCpcNorm
          maxCpc
          minCpc
          maxCpa
          minCpa
          minCpaNorm
          cpcCpaCnvrVal
          expiredWhere
          netCpc
          targetCr
          lastDownload
          jatRating
          jatExclude
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          feedDate
          redirectTo
          feedFile
          publisher
          publisherId
          quality
          edr
          pace
          cr
          rkj
          pkj
          budget
          dailyBudget
          stopBudgetExceed
          costCpaAdj
          revMtd
          costMtd
          roiMtd
          feedType
          exportType
          costCpaMtd
          mediumSrc
          recoupAllTraffic
          campaigns {
            key
            name
            volume
            rating
          }
          skipTqf
          acceptRevType
          pubQuality
          pubProfitability
          pubAutoQuality
          priority
        }
      }
    }
  }
`;

export const GET_EXPORT_HISTORY = gql`
  query getExportHistory($id: Int) {
    getExportHistory(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          history {
            id
            log
            userId
          }
        }
      }
    }
  }
`;

export const GENERATE_EXPORT_API_KEY = gql`
  mutation exportApiKey($id: Int) {
    exportApiKey(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          apiKey
        }
      }
    }
  }
`;

export const GET_EXPORT_RATES = gql`
  query GetExportRates($id: Int) {
    exportRates(id: $id) {
      code
      success
      message
      payload {
        nodes {
          cpc {
            name
            value
          }
          cpa {
            name
            value  
          }
        }
      }
    }
  }
`;

export const GET_EXPORT_CAMPS = gql`
  query GetExportCamps($id: Int) {
    exportCamps(id: $id) {
      code
      success
      message
      payload {
        nodes {
          name
          value
        }
      }
    }
  }
`;

export const GET_CAMPS_BY_PUB_FEED_ID = gql`
  query GetCampsByPubFeedId($skip: Int, $take: Int, $sort: [Sort], $id: Int, $notLinked: Boolean, $name: String) {
    getCampsByPubFeedId(skip: $skip, take: $take, sort: $sort, id: $id, notLinked: $notLinked, name: $name) {
      payload {
        nodes {
          id
          isActive
          name
          rev
          roi
          costCpc
          costCpa
          tqfCr
          campaignId
          matrixId
          netCpc
          tqf
          tqfId
          sign
          format
          quality
          profitability
          autoQuality
          editMatrix
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_SCF_BY_ID = gql`
  query getSmartContractsFieldsById($id: Int) {
    getSmartContractsFields(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          paidDuplicateClickTime
          paidDuplicateClickLongTime
          paidForeignClick
          paidApplyClosedJob
          paidApplyExpiredJob
          paidApplyForeignJob
          paidIpqsScoreLimit
          paidIsProxy
          paidIsTor
          paidIsAnonymous
          paidIsVpn
          duplicateApplyLong
          applyMaxPaidDistance
          applyPaidIpqsScore
          applyGracePeriod
          maxPaidDist
          maxPaidFraudScore
      }
      }
    }
  }
`;

export const ADD_ADVERTISER = gql`
  mutation AddAdvertiser($node: AdvertiserActionInput) {
    addAdvertiser(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          name
          schemaAdv
          isActive
          rating
          typeAdv
          comments
          history
          billing
          adOps
          expired
          monthBudget
          agencyFee
          isPayExpired
          isPayExpired24
          duplicateLogic
          payCustom
          login
          password
          isReconcileExternal
          externalUrl
          externalLogin
          externalPassword
        }
      }
    }
  }
`;

export const ADD_EXPORT = gql`
  mutation AddExport($node: ExportActionInput) {
    addExport(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          name
          traderId
          trader
          siteAdminId
          executiveId
          executive
          status
          payType
          live
          isDisabled
          isReSyndicated
          skipTqf
          jobsCount
          jobsCountLimit
          queueJobsToggle
          minCpcNorm
          maxCpc
          minCpc
          maxCpa
          minCpa
          minCpaNorm
          cpcCpaCnvrVal
          expiredWhere
          netCpc
          targetCr
          jatRating
          jatExclude
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          lastDownload
          feedDate
          redirectTo
          feedFile
          publisher
          costMtd
          cr
          edr
          quality
          revMtd
          roiMtd
          volume
          budget
          dailyBudget
          stopBudgetExceed
          publisherId
          feedType
          exportType
          mediumSrc
          recoupAllTraffic
          acceptRevType
          pace
          pubQuality
          pubProfitability
          pubAutoQuality
        }
      }
    }
  }
`;

export const UPDATE_SCF = gql`
  mutation updateSmartContractsFields($node: SmartContractsFieldsActionInput, $id: Int) {
    updateSmartContractsFields(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          paidDuplicateClickTime
          paidDuplicateClickLongTime
          paidForeignClick
          paidApplyClosedJob
          paidApplyExpiredJob
          paidApplyForeignJob
          paidIpqsScoreLimit
          paidIsProxy
          paidIsTor
          paidIsAnonymous
          paidIsVpn
          duplicateApplyLong
          applyMaxPaidDistance
          applyPaidIpqsScore
          applyGracePeriod
          maxPaidDist
          maxPaidFraudScore
        }
      }
    }
  }
`;

export const UPDATE_EXPORT = gql`
  mutation UpdateExport($node: ExportActionInput, $id: Int) {
    updateExport(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          traderId
          trader
          siteAdminId
          executiveId
          executive
          name
          status
          payType
          live
          isDisabled
          isReSyndicated
          skipTqf
          jobsCountLimit
          queueJobsToggle
          minCpcNorm
          maxCpc
          minCpc
          maxCpa
          minCpa
          minCpaNorm
          cpcCpaCnvrVal
          expiredWhere
          netCpc
          targetCr
          goal
          jatRating
          jatExclude
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          publisher
          publisherId
          feedType
          exportType
          feedFile
          budget
          dailyBudget
          stopBudgetExceed
          recoupAllTraffic
          costCpaAdj
          mediumSrc
          acceptRevType
          pace
          pubQuality
          pubProfitability
          pubAutoQuality
          priority
        }
      }
    }
  }
`;

export const CLONE_EXPORT = gql`
  mutation CloneExport($id: Int) {
    cloneExport(id: $id) {
      code
      success
      message
      payload {
        node {
          id
          name
          tqfId
          traderId
          trader
          siteAdminId
          siteAdmin
          executiveId
          executive
          status
          payType
          live
          isDisabled
          isReSyndicated
          jobsCount
          jobsCountLimit
          queueJobsToggle
          minCpcNorm
          maxCpc
          minCpc
          maxCpa
          minCpa
          minCpaNorm
          cpcCpaCnvrVal
          expiredWhere
          netCpc
          targetCr
          lastDownload
          jatRating
          jatExclude
          billOff
          billOffUrl
          billOffLogin
          billOffPass
          feedDate
          decreasing
          redirectTo
          feedFile
          publisher
          publisherId
          quality
          volume
          ecpa
          edr
          cr
          rkj
          pkj
          jobsCountTrend
          budget
          dailyBudget
          stopBudgetExceed
          revMtd
          mtdRevenue
          goal
          costMtd
          roiMtd
          roiMtdP
          clickAvg
          blendCr
          feedType
          costCpaMtd
          costAvgCpa
          recoupAllTraffic
          eom
          pace
          mediumSrc
          costCpaAdj
          jobsCountHistory {
              key
              name
          }
          tqfList {
              id
              name
              campaignGroupId
          }
          showInReports
          showInTqf
          editSCF
          campsEnabled
          pubEmail
          jobsApiKey
          skipTqf
          pubQuality
          pubProfitability
          pubAutoQuality
        }
      }
    }
  }
`;

export const DELETE_EXPORT = gql`
  mutation DeleteExport($id: Int) {
    deleteExport(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_FEEDS_PREVIEW = gql`
  query GetFeedsPreview($filter: [Filter], $filterType: String) {
    feedsPreview(filter: $filter, filterType: $filterType) {
      payload {
        nodes {
          partnerId
          positionTitle
          partnerEmployer
          partnerCpc
          partnerCpa
          zip
          country
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_LOGS = gql`
  query GetLogs($filter: [Filter], $filterType: String) {
    logs(filter: $filter, filterType: $filterType) {
      payload {
        nodes {
          id
          createdAt
          partnerId
          user
          type
          rate
          numJobs
          sinceLast
          log
          comments
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_MATRIX = gql`
  mutation UpdateMatrix(
      $node: MatrixUpdateInput, $id: Int, $groupedId: Int,
      $subgroupedId: Int, $isMassEdit: Boolean, $userEmail: String,
  ) {
    updateMatrix(
        node: $node, id: $id, groupedId: $groupedId,
        subgroupedId: $subgroupedId, isMassEdit: $isMassEdit, userEmail: $userEmail) {
      code
      success
      message
      payload {
        node {
          uid
          isActive
          isDeactive
          isBlockCampaign
          isActiveUntilBudget
          bidAdjustment
          jobCountLimit
          sign
          sign
          format
          maxCpc
          minCpc
          minCpcFilter
          maxCpa
          minCpa
          minCpaFilter
          targetCr
          targetCpa
          targetCpaCost
          dayBudget
          monthBudget
          totalBudget
          optimize
          expiredWhere
          rdr2Boomerang
          botProtection
          fraudProtection
          fraudScoreFilter
          validate
          isActiveTqf
          tqfId
          tqf
          tqfType
          recoupAllTraffic
          costCpaAdj
          updMassList
          tApplyEnabled
          tApplyPercentage
          tApplyVariability
          tApplyMinDelaySec
          tApplyMaxDelaySec
          campaigns {
              key
              name
              uid
          }
        }
      }
    }
  }
`;

export const UPDATE_MATRIX_QUICK_BID = gql`
  mutation UpdateMatrixQuickBid($id: Int, $change: Int) {
    updateMatrixQuickBid(id: $id, change: $change) {
      code
      success
      message
      payload {
        node {
          uid
          bidAdjustment
          sign
          format
        }
      }
    }
  }
`;

export const GET_JOB_GORUPS = gql`
  query GetJobGroups($type: String) {
    jobGroups(type: $type) {
      code
      success
      message
      payload {
        nodes {
          id
          title
          minRev
          filter {
            columnName
            value
            operation
          }
        }
        meta {
          totalCount
        }
      }

    }
  }
`;

export const DELETE_JOB_GROUP = gql`
  mutation DeleteJobGroup($id: Int) {
    deleteJobGroup(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const ADD_JOB_GROUP = gql`
  mutation AddJobGroup($node: JobGroupActionInput) {
    addJobGroup(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          title
          type  
          filter {
            columnName
            value
            operation 
          }
          campaign {
            id
            rating
            level
            parentName
            parentId
            feedName
            feedId
            targetCpa
            targetCpaCost
            minCpc
            monthBudget
            dayBudget
            comment
            fixedCpa
            rev
            jatRating
            netCpc
            bid
            name
            filters
            groups
            cdate
            jobsCount
            recoupAllTraffic
            userEmail
            tapplyEnabled
            tapplyPercentage
            tapplyVariability
            isScaling
          } 
        }
      }
    }
  }
`;

export const UPDATE_JOB_GROUP = gql`
  mutation UpdateJobGroup($node: JobGroupActionInput, $id: Int) {
    updateJobGroup(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          title
          filter {
            columnName
            value
            operation
          }
        }
      }
    }
  }
`;

export const GET_USER_SETTINGS = gql`
    query getUserSettings($settingsName: String, $userEmail: String,) {
        getUserSettings(settingsName: $settingsName, userEmail: $userEmail) {
            code
            success
            message
            payload {
                node
            } 
        }
    }
`;

export const UPDATE_USER_SETTINGS = gql`
    mutation updateUserSettings($node: String, $settingsName: String, $userEmail: String,) {
        updateUserSettings(node: $node, settingsName: $settingsName, userEmail: $userEmail) {
            code
            success
            message
            payload {
                node
            }
        }
    }
`;

export const EXPORT_EVENTS_TO_GOOGLE_DRIVE = gql`
  mutation exportEventsToGoogleDrive($filter: [Filter], $filterType: String, $fields: [String], $accessToken: String) {
    exportEventsToGoogleDrive(filter: $filter, filterType: $filterType, fields: $fields, accessToken: $accessToken) {
      code
      success
      message
      payload
    }
  }
`;

export const EXPORT_REPORT_TO_GOOGLE_DRIVE = gql`
  mutation exportReportToGoogleDrive($filter: [Filter], $group: Group, $filterByDate: [Filter], $fields: [String], $accessToken: String) {
    exportReportToGoogleDrive(filter: $filter, group: $group, filterByDate: $filterByDate, fields: $fields, accessToken: $accessToken) {
      code
      success
      message
      payload
    }
  }
`;

export const EXPORT_LIVE_JOBS_TO_GOOGLE_DRIVE = gql`
  mutation exportLiveJobsToGoogleDrive($filter: [Filter], $fields: [String], $accessToken: String) {
    exportLiveJobsToGoogleDrive(filter: $filter, fields: $fields, accessToken: $accessToken) {
      code
      success
      message
      payload
    }
  }
`;

export const GET_DOWNLOAD_LINK = gql`
  query getDownloadLink($filter: [Filter], $filterType: String, $fields: [String]) {
    getDownloadLink(filter: $filter, filterType: $filterType, fields: $fields) {
      code
      success
      message
      payload
    }
  }
`;

export const GET_DEFAULT_FIELDS_CHECKED = gql`
  query getDefaultFieldsChecked {
    getDefaultFieldsChecked {
      code
      success
      message
      payload
    }
  }
`;

export const GET_DOWNLOAD_REPORT_LINK = gql`
  query getDownloadReportLink($filter: [Filter], $group: Group, $filterByDate: [Filter], $fields: [String]) {
    getDownloadReportLink(filter: $filter, group: $group, filterByDate: $filterByDate, fields: $fields) {
      code
      success
      message
      payload
    }
  }
`;

export const GET_DOWNLOAD_LIVE_JOBS_LINK = gql`
  query getDownloadLiveJobsLink($filter: [Filter], $fields: [String]) {
    getDownloadLiveJobsLink(filter: $filter, fields: $fields) {
      code
      success
      message
      payload
    }
  }
`;

export const UPDATE_SELECTIONS = gql`
  mutation UpdateSelections($node: SelectionsUpdateInput) {
    updateSelections(node: $node) {
      code
      success
      message
      payload {
        node {
          id {
            key
            name
          }
          selections {
            key
            name
          }
          type
          action
        }
      }
    }
  }
`;

export const UPDATE_SELECTION_BY_ID = gql`
  mutation UpdateSelectionById($node: SelectionUpdateInput) {
    updateSelectionById(node: $node) {
      code
      success
      message
      payload {
        node {
          id 
          name
        }
      }
    }
  }
`;

export const GET_TQF_BY_ID = gql`
  query getTqfById($id: Int, $type: String, $subId: Int, $subType: String) {
    getTqf(id: $id, type: $type, subId: $subId, subType: $subType) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          tqfCheck
          name
#          createdBy
#          createdName
          advertiserId
          feedId
          campaignId
          publisherId
          campGroupId
          type
          typeName
          tqf
          tqf2
          type
          untilDate
          redirectTo
          redirectTo2
          distanceCheck
          distance
          forceDistanceCheck
          forceDistance
          forceDistanceRedirect
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          intentType
          intentValue
          override
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          untilActive
          untilHours
        }
      }
    }
  }
`;

export const GET_TQF_HISTORY = gql`
  query getTqfHistory($id: Int) {
    getTqfHistory(id: $id) {
      code
      success
      message
      payload {
        node {
          id  
          history {
            id
            log
            userId
          }
        }
      }
    }
  }
`;

export const UPDATE_TQF = gql`
  mutation UpdateTqf($node: TqfActionInput, $id: Int, $userEmail: String) {
    updateTqf(node: $node, id: $id, userEmail: $userEmail) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          tqfCheck
          name
          clone
          feedId
          campaignId
          publisherId
          advertiserId
          campGroupId
          tqf
          tqf2
          untilDate
          redirectTo
          redirectTo2
          distanceCheck
          distance
          forceDistanceCheck
          forceDistance
          forceDistanceRedirect
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          intentType
          intentValue
          type
          typeName
          override
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          untilActive
          untilHours
        }
      }
    }
  }
`;

export const GET_NETWORK_ADVERTISERS = gql`
  query GetNetworkAdvertisers($filter: [Filter], $filterType: String) {
    networkAdvertisers(filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          salesId
          siteAdminId
          siteAdmin
          salesName
          schemaAdv
          isActive
          goal
          rating
          jatRating
          typeAdv
          comments
          history
          billing
          adOps
          expired
          monthBudget
          agencyFee
          isPayExpired
          isPayExpired24
          duplicateLogic
          hidden
          email
          login
          password
          contact
          isReconcileExternal
          externalUrl
          externalLogin
          externalPassword
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_NETWORK_ADVERTISER_BY_ID = gql`
  query GetNetworkAdvertiserById($id: Int)  {
    networkAdvertiser(id: $id)   {
      code
      success
      message
      payload {
        node {
          id
          name
          salesId
          siteAdminId
          schemaAdv
          isActive
          hidden
          rating
          jatRating
          goal
          typeAdv
          comments
          history
          billing
          adOps
          expired
          monthBudget
          agencyFee
          isPayExpired
          isPayExpired24
          duplicateLogic
          email
          login
          password
          contact
          isReconcileExternal
          externalUrl
          externalLogin
          externalPassword
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_ADVERTISER = gql`
  mutation UpdateNetworkAdvertiser($node: NetworkAdvertiserUpdateInput, $id: Int) {
    updateNetworkAdvertiser(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          salesId
          siteAdminId
          name
          schemaAdv
          goal
          rating
          jatRating
          typeAdv
          comments
          history
          billing
          adOps
          expired
          monthBudget
          agencyFee
          isPayExpired
          isPayExpired24
          duplicateLogic
          login
          password
          isReconcileExternal
          externalUrl
          externalLogin
          externalPassword
        }
      }
    }
  }
`;

export const ADD_NETWORK_ADVERTISER = gql`
  mutation AddNetworkAdvertiser($node: NetworkAdvertiserAddInput) {
    addNetworkAdvertiser(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          salesId
          siteAdminId
          name
          schemaAdv
          goal
          rating
          jatRating
          typeAdv
          comments
          history
          billing
          adOps
          expired
          monthBudget
          agencyFee
          isPayExpired
          isPayExpired24
          duplicateLogic
          login
          password
          isReconcileExternal
          externalUrl
          externalLogin
          externalPassword
        }
      }
    }
  }
`;

export const DELETE_NETWORK_ADVERTISER = gql`
  mutation DeleteNetworkAdvertiser($id: Int) {
    deleteNetworkAdvertiser(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_NETWORK_PUBLISHERS = gql`
  query GetNetworkPublishers($filter: [Filter], $filterType: String)  {
    networkPublishers(filter: $filter, filterType: $filterType)   {
      code
      success
      message
      payload {
        nodes {
          id
          isMaster
          isActive
          salesId
          salesName
          parentId
          parentName
          campaignGroupId
          name
          contact
          email
          password
          phone
          floorCpc
          frequency
          discrepancy
          budget
          dailyBudget
          pace
          goal
          costMultiple
          expired
          apikey
          hidden
          mtdCost
          payExpired
          payExpired24
          comments
          quality
          volume
          latency
          dlBlock
          exportType
          paidClicks
          bidAdj
          reportEmail
          reportExpired
          sendReport
          paidExpired
          paidExpired24
          rating
          externalUrl
          externalLogin
          externalPassword
          isReconcileExternal
          pixelCode
          enablePixelCode
          subSource
          deleted
          jatRating
          parentIdOnly
          parentIdId
          siteAdminId
          siteAdmin
        }
        meta {
            totalCount
        }
      }
    }
  }
`;

export const GET_NETWORK_PUBLISHER_BY_ID = gql`
  query GetNetworkPublisherById($id: Int)  {
    networkPublisher(id: $id)   {
      code
      success
      message
      payload {
        node {
          id
          isMaster
          isActive
          salesId
          parentId
          campaignGroupId
          name
          contact
          email
          password
          phone
          floorCpc
          frequency
          discrepancy
          budget
          dailyBudget
          pace
          goal
          costMultiple
          expired
          apikey
          hidden
          mtdCost
          payExpired
          payExpired24
          comments
          quality
          volume
          latency
          dlBlock
          exportType
          paidClicks
          bidAdj
          reportEmail
          reportExpired
          sendReport
          paidExpired
          paidExpired24
          rating
          externalUrl
          externalLogin
          externalPassword
          isReconcileExternal
          pixelCode
          enablePixelCode
          subSource
          deleted
          jatRating
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const ADD_NETWORK_PUBLISHER = gql`
  mutation AddNetworkPublisher($node: NetworkPublisherAddInput) {
    addNetworkPublisher(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          isMaster
          name
          contact
          salesId
          parentId
          campaignGroupId
          budget
          dailyBudget
          rating
          mtdCost
          floorCpc
          frequency
          discrepancy
          pace
          bidAdj
          goal
          costMultiple
          dlBlock
          expired
          exportType
          quality
          volume
          latency
          hidden
          payExpired
          payExpired24
          paidClicks
          enablePixelCode
          pixelCode
          subSource
          email
          password
          createAuth0
          auth0Role
          isReconcileExternal
          jatRating
          externalUrl
          externalLogin
          externalPassword
          comments
        }
      }
    }
  }
`;

export const UPDATE_NETWORK_PUBLISHER = gql`
  mutation UpdateNetworkPublisher($node: NetworkPublisherUpdateInput, $id: Int) {
    updateNetworkPublisher(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          isMaster
          name
          contact
          salesId
          parentId
          campaignGroupId
          budget
          dailyBudget
          rating
          mtdCost
          floorCpc
          frequency
          discrepancy
          pace
          bidAdj
          goal
          costMultiple
          dlBlock
          expired
          exportType
          quality
          volume
          latency
          hidden
          payExpired
          payExpired24
          paidClicks
          enablePixelCode
          pixelCode
          subSource
          email
          password
          createAuth0
          auth0Role
          isReconcileExternal
          jatRating
          externalUrl
          externalLogin
          externalPassword
          comments
        }
      }
    }
  }
`;

export const DELETE_NETWORK_PUBLISHER = gql`
  mutation DeleteNetworkPublisher($id: Int) {
    deleteNetworkPublisher(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_TQF = gql`
  query GetTqf($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    tqf(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const DELETE_TQF = gql`
  mutation DeleteTqf($ids: String) {
    deleteTqf(ids: $ids) {
      code
      success
      message
      payload {
        ids
      }
    }
  }
`;

export const GET_JOBS_SETTINGS = gql`
  query GetJobsSettings($filter: [Filter], $filterType: String) {
    jobsSettings(filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          value
          valueType
          description
          createdAt
          updatedAt
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_JOBS_SETTINGS = gql`
  mutation UpdateJobsSettings($node: JobsSettingsUpdateInput, $id: Int) {
    updateJobsSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          value
        }
      }
    }
  }
`;

export const GET_JOBS_PUB_SETTINGS = gql`
  query GetJobsPubSettings($filter: [Filter], $filterType: String) {
    jobsPubSettings(filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          isActive
          revMtd
          cr
          roi
          campaignGroupId
          partnerId
          partnerType
          maxItemsPerPage
          paginationEnabled
          maxSize
          descriptionOption
          rateLimit
          rateWindow
          defaultSort
          urlParameters
          filters
          excludeFeeds
          excludeJobAdType
          sourceFields
          searchResponseType
          campaignGroup
          publisher
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_JOBS_PUB_SETTINGS = gql`
  mutation UpdateJobsPubSettings($node: JobsPubSettingsInput, $id: Int) {
    updateJobsPubSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          partnerType
          maxItemsPerPage
          paginationEnabled
          maxSize
          descriptionOption
          rateLimit
          rateWindow
          defaultSort
          urlParameters
          filters
          excludeFeeds
          excludeJobAdType
          sourceFields
        }
      }
    }
  }
`;

export const ADD_JOBS_PUB_SETTINGS = gql`
  mutation AddJobsPubSettings($node: JobsPubSettingsInput) {
    addJobsPubSettings(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          partnerType
          maxItemsPerPage
          paginationEnabled
          maxSize
          descriptionOption
          rateLimit
          rateWindow
          defaultSort
          urlParameters
          filters
          excludeFeeds
          excludeJobAdType
          sourceFields
        }
      }
    }
  }
`;

export const DELETE_JOBS_PUB_SETTINGS = gql`
  mutation DeleteJobsPubSettings($id: Int) {
    deleteJobsPubSettings(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_JOBS_ADV_SETTINGS = gql`
  query GetJobsAdvSettings($filter: [Filter], $filterType: String) {
    jobsAdvSettings(filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          campaignGroupId
          campaignGroup
          advFeedId
          advFeed
          maxCpc
          netCpcRatio
          netCpc
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_JOBS_ADV_SETTINGS = gql`
  mutation UpdateJobsAdvSettings($node: JobsAdvSettingsInput, $id: Int) {
    updateJobsAdvSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          advFeedId
          maxCpc
          netCpc
        }
      }
    }
  }
`;

export const ADD_JOBS_ADV_SETTINGS = gql`
  mutation AddJobsAdvSettings($node: JobsAdvSettingsInput) {
    addJobsAdvSettings(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          advFeedId
          maxCpc
          netCpc
        }
      }
    }
  }
`;

export const DELETE_JOBS_ADV_SETTINGS = gql`
  mutation DeleteJobsAdvSettings($id: Int) {
    deleteJobsAdvSettings(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_JOBS_GLOBAL_SETTINGS = gql`
  query GetJobsGlobalSettings($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String) {
    jobsGlobalSettings(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          campaignGroupId
          campaignGroup
          subSrcId
          excludeFeed
          excludeEmployer
          excludeJobAdType
          includeJobAdType
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const UPDATE_JOBS_GLOBAL_SETTINGS = gql`
  mutation UpdateJobsGlobalSettings($node: JobsGlobalSettingsInput, $id: Int) {
    updateJobsGlobalSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          subSrcId
          excludeFeed
          excludeEmployer
          excludeJobAdType
          includeJobAdType
        }
      }
    }
  }
`;

export const ADD_JOBS_GLOBAL_SETTINGS = gql`
  mutation AddJobsGlobalSettings($node: JobsGlobalSettingsInput) {
    addJobsGlobalSettings(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          subSrcId
          excludeFeed
          excludeEmployer
          excludeJobAdType
          includeJobAdType
        }
      }
    }
  }
`;

export const DELETE_JOBS_GLOBAL_SETTINGS = gql`
  mutation DeleteJobsGlobalSettings($id: Int) {
    deleteJobsGlobalSettings(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_GEOFENCE = gql`
  query GeoFence($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    geoFence(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_VOLUME = gql`
  query Volume($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    volume(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_CRO = gql`
  query Cro($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    cro(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_PROTECTION_RECOUP = gql`
  query ProtectionRecoup($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    protectionRecoup(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          target
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_CPAC_CONVERT = gql`
  query CpacConvert($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $isGhost: Boolean) {
    cpacConvert(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, isGhost: $isGhost) {
      payload {
        nodes {
          id
          isActive
          tqfCheck
          showInReports
          name
          createdBy
          createdName
          advertiserId
          advTitle
          feedId
          feedTitle
          publisherId
          pubTitle
          campaignId
          campTitle
          campGroupId
          campGroupTitle
          redirectTo
          redirectTo2
          tqf
          tqf2
          type
          typeName
          distanceCheck
          distance
          forceDistance
          forceDistanceCheck
          forceDistanceRedirect
          intentType
          intentValue
          intentStart
          intentEnd
          triggerBudget
          triggerBudgetPeriod
          triggerBudgetAction
          jatRating
          jat1Rating
          target
          ruleScore
          override
          exitClickCount
          spikeCheck
          spikeClicks
          spikeSpend
          spikeCrCheck
          spikeCr
          spikeCrMore
          spikeBudgetCheck
          spikeBudget
          spikeExpiredCheck
          spikeExpiredTime
          spikeQualityCheck
          spikeQualityFraud
          cnt
          cntP
          cr
          tqfCr
          tqfCrPercent
          fcr
          cpac
          cost
          costP
          roi
          roiPercentage
          deltaCr
          tqfBoost
          updated
          updatedName
          untilDate
          untilActive
          untilHours
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_TODO = gql`
  query Todo($filter: [Filter], $userEmail: String) {
    todo(filter: $filter, userEmail: $userEmail) {
      payload {
        nodes {
          todo {
            id
            parentId
            page
            name
            type
            email
            nickName
            state
            priority
            dueDate
            startDate
            finishDate
          }
          users {
            id
            email
            firstName
            lastName
          }
        }
        meta {
          totalCount
          totalActiveCount
        }
      }
    }
  }
`;

export const UPDATE_TODO = gql`
  mutation UpdateTodo($node: TodoActionInput, $id: Int ) {
    updateTodo(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          parentId
          page
          name
          email
          type
          state
          priority
          dueDate
        }
      }
    }
  }
`;

export const ADD_TODO = gql`
  mutation AddTodo($node: TodoActionInput) {
    addTodo(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          parentId
          page
          name
          email
          type
          state
          priority
          dueDate
        }
      }
    }
  }
`;

export const DELETE_TODO = gql`
    mutation DeleteTodo($id: Int) {
        deleteTodo(id: $id) {
            code
            success
            message
            payload {
                id
            }
        }
    }
`;

export const GET_COMMENTS = gql`
  query Comments($userEmail: String, $type: String, $id: Int) {
    comments(userEmail: $userEmail, type: $type, id: $id) {
      payload {
        nodes {
          id
          comment
          type,
          email,
          createdAt,
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const ADD_COMMENT = gql`
  mutation AddComment($node: CommentsActionInput, $userEmail: String, $type: String, $id: Int) {
    addComment(node: $node, userEmail: $userEmail, type: $type, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          comment
        }
      }
    }
  }
`;

export const DELETE_COMMENT = gql`
    mutation DeleteComment($id: Int) {
        deleteComment(id: $id) {
            code
            success
            message
            payload {
                id
            }
        }
    }
`;

export const GET_PUB_FEEDS_SETTINGS = gql`
  query PubFeedsSettings($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String) {
    pubFeedsSettings(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType) {
      code
      success
      message
      payload {
        nodes {
          id
          isActive
          campaignGroup
          campaignGroupId
          publisher
          jobsApiKey
          minCpcNorm
          revMtd
          cr
          roi
          apiType
          showInReports
          editSCF
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_PUB_FEEDS_SETTINGS_BY_ID = gql`
  query getPubFeedSettingsById($id: Int, $getBy: String) {
    getPubFeedSettings(id: $id, getBy: $getBy) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          netCpc
          minCpc
          maxCpc
          excludeFeeds
          excludeEmployers
          excludeKeywords
          excludeJobAdType
          maxItemsPerPage
          defaultSort
          descriptionOption
          pagination
          rateLimit
          rateWindow
          maxSize
          advFeeds {
            id
            campaignGroupId
            advFeedId
            advFeed
            maxCpc
            netCpcRatio
            netCpc
            jatRating
          }
          subSources {
            id
            campaignGroupId
            subSrcId
            excludeFeeds
            excludeEmployers
            excludeJobAdType
            includeJobAdType
            subSrcBidAdj
          }
        }
      }
    }
  }
`;

export const ADD_PUB_FEED_SETTINGS = gql`
  mutation AddPubFeedSettings($node: PubFeedSettingsAddInput) {
    addPubFeedSettings(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          netCpc
          minCpc
          maxCpc
          excludeFeeds
          excludeEmployers
          excludeKeywords
          excludeJobAdType
          maxItemsPerPage
          defaultSort
          descriptionOption
          pagination
        }
      }
    }
  }
`;

export const UPDATE_ACTIVATE_PUB_FEED_SETTINGS = gql`
  mutation UpdatePubFeedActivateSettings($node: PubFeedSettingsActivateInput, $id: Int) {
    updatePubFeedActivateSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          isActive
          campaignGroup
          publisher
          revMtd
          cr
          roi
        }
      }
    }
  }
`;

export const UPDATE_PUB_FEED_SETTINGS = gql`
  mutation UpdatePubFeedSettings($node: PubFeedSettingsUpdateInput, $id: Int) {
    updatePubFeedSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          netCpc
          minCpc
          maxCpc
          excludeFeeds
          excludeEmployers
          excludeKeywords
          excludeJobAdType
          maxItemsPerPage
          defaultSort
          descriptionOption
          pagination
        }
      }
    }
  }
`;

export const ADD_SUB_SRC_SETTINGS = gql`
  mutation AddSubSrcSettings($node: SubSrcSettingsUpdateInput) {
    addSubSrcSettings(node: $node) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          subSrcId
          excludeFeeds
          excludeEmployers
          excludeJobAdType
          includeJobAdType
          subSrcBidAdj
        }
      }
    }
  }
`;

export const UPDATE_SUB_SRC_SETTINGS = gql`
  mutation UpdateSubSrcSettings($node: SubSrcSettingsUpdateInput, $id: Int) {
    updateSubSrcSettings(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          campaignGroupId
          subSrcId
          excludeFeeds
          excludeEmployers
          excludeJobAdType
          includeJobAdType
          subSrcBidAdj
        }
      }
    }
  }
`;

export const UPDATE_ALL_CAMPS_BY_FEED_ID = gql`
  mutation UpdateAllCampsByPubFeed($node: updAllCampsByPubFeedActionInput, $id: Int) {
    updateAllCampsByPubFeed(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          maxCpc
        }
      }
    }
  }
`;

export const DELETE_SUB_SRC_SETTINGS = gql`
  mutation DeleteSubSrcSettings($id: Int) {
    deleteSubSrcSettings(id: $id) {
      code
      success
      message
      payload {
        id
      }
    }
  }
`;

export const GET_JOBS_API_WEBSITES = gql`
  query JobsApiWebsites($filter: [Filter], $filterType: String, $userEmail: String) {
    jobsApiWebsites(filter: $filter, filterType: $filterType, userEmail: $userEmail) {
      code
      success
      message
      payload {
        nodes {
          id
          url
          state
          createdAt  
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const DELETE_JOBS_API_WEBSITES = gql`
  mutation DeleteJobsApiWebsites($id: Int) {
    deleteJobsApiWebsites(id: $id) {
      code
      success
      message
      payload {
          id
      }
    }
  }
`;

export const ADD_JOBS_API_WEBSITES = gql`
  mutation AddJobsApiWebsites($node: WebsiteInput, $userEmail: String) {
    addJobsApiWebsites(node: $node, userEmail: $userEmail) {
      code
      success
      message
      payload {
        node {
          id
          url
          state
          createdAt
        }
      }
    }
  }
`;

export const UPDATE_JOBS_API_WEBSITES = gql`
  mutation UpdateJobsApiWebsites($node: WebsiteInput, $id: Int) {
    updateJobsApiWebsites(node: $node, id: $id) {
      code
      success
      message
      payload {
        node {
          id
          url
          state
          createdAt
        }
      }
    }
  }
`;

export const SEND_NOTIFY_ALL = gql`
  mutation NotifyAll($node: NotifyInput) {
    notifyAll(node: $node) {
      code
      success
      message
      payload {
        node {
          type
          subject
          body
        }
      }
    }
  }
`;

export const SEND_EMAIL = gql`
  mutation sendEmail($node: SendEmailInput) {
    sendEmail(node: $node) {
      code
      success
      message
      payload {
        node {
          email
          subject
          body
        }
      }
    }
  }
`;

export const ACTIVATE_PUB_FEEDS_BY_CAMP_ID = gql`
  mutation activatePubFeedsByCampId($id: Int, $feedId: Int, $isActive: Boolean) {
    activatePubFeedsByCampId(id: $id, feedId: $feedId, isActive: $isActive) {
      code
      success
      message
      payload {
        node {
          id
          feedId
          isActive
        }
      }
    }
  }
`;

export const GET_ADV_BILLING = gql`
  query getAdvBilling($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $userEmail: String, $month: String) {
    getAdvBilling(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, userEmail: $userEmail, month: $month) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          advertiser
          agencyFee
          ptpRev
          edrRev
          bill
          billAdj
          billCustom
        }
        count {
          bill
          billAdj
          billCustom
          ptpRev
          edrRev
        }
        meta {
          totalCount
        }
      }
    }
  }
`;

export const GET_PUB_BILLING = gql`
  query getPubBilling($skip: Int, $take: Int, $sort: [Sort], $filter: [Filter], $filterType: String, $userEmail: String, $month: String) {
    getPubBilling(skip: $skip, take: $take, sort: $sort, filter: $filter, filterType: $filterType, userEmail: $userEmail, month: $month) {
      code
      success
      message
      payload {
        nodes {
          id
          name
          publisher
          ptpRev
          edrRev
          bill
          billAdj
          billCustom
        }
        count {
          bill
          billAdj
          billCustom
          ptpRev
          edrRev
        }
        meta {
          totalCount
        }
      }
    }
  }
`;
